import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, SiteTitle, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'



import GNBimg3 from "../../images/GNB/3.jpg"
import GNBimg4 from "../../images/GNB/4.jpg"
import GNBimg5 from "../../images/GNB/5.jpg"


import gnb from "../../images/GNB/gnb.mp4"
import gnbfull from "../../images/GNB/gnbfull.mp4"

// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;




export default ({ location }) => (
  <Layout>

    <SiteTitle string="Electric Floating Island" />


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    ewz is the electricity provider in Zürich and state-owned, so their customers are also voters.
                    <p/>
                    <a href="http://www.kilokilo.ch" target="_blank">KiloKilo (digital agency)</a>, <a href="http://www.stephaniestutz.ch" target="_blank">Stephanie Stutz (game designer/ illustrator)</a> and <LinkABOUT location={location}>I</LinkABOUT> created <a href="https://www.ewz.ch/gnb/de/startseite.html">an interactive mini-world.</a> My role was developing the creative concept and aligning it with the strategic goal of ewz: to make their projects transparent at a glance.
                    <p/>

                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>



              </Type>
        </English>


         <Deutsch Language={location.search}>
              <Type>
                    ewz ist das Elektrizitätswerk der Stadt Zürich und staatlich, das heisst seine Kunden können auch abstimmen.
                    <p/>
                    <a href="http://www.kilokilo.ch" target="_blank">KiloKilo (Entwicklung/Design)</a>, <a href="http://www.stephaniestutz.ch" target="_blank">Stephanie Stutz (Illustration)</a> und <LinkABOUT location={location}>ich</LinkABOUT> entwickelten <a href="https://www.ewz.ch/gnb/de/startseite.html">eine interaktive Miniwelt.</a> Meine Rolle war das Entwickeln des Kreativkonzepts und dieses auf das strategische Ziel von ewz auszurichten: ihre Projekte auf einen Blick transparent zu machen. 
                    <p/>

                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>



              </Type>
        </Deutsch>

    </div>

    <Element name="test1"></Element>





    <video  autoPlay loop muted>
<source src={gnb} type="video/mp4" />
Your browser does not support the video tag.
</video>


      <div className="comment">
        <English Language={location.search}>
              <Type>
                    We recreated the whole world of ewz as a condensed Zürich and Switzerland, floating on an isolated island. In retrospect, this is also political realism.
              </Type>
        </English>


            <Deutsch Language={location.search}>
              <Type>
                    Wir erschufen die ganze Welt von ewz als ein komprimiertes Zürich mit Teilen der Schweiz, die auf einer isolierten Insel schwebten. Rückblickend ist das auch politischer Realismus.
              </Type>
        </Deutsch>
      </div>




      <video  autoPlay loop muted>
<source src={gnbfull} type="video/mp4" />
Your browser does not support the video tag.
</video>




      <div className="comment">
        <English Language={location.search}>
              <Type>
                    Users could zoom into this world. Inspired by mechanics from gamedesign, sidescrolls show the energy projects ewz is involved in.
              </Type>
        </English>


        <Deutsch Language={location.search}>
              <Type>
                    Besucher konnten sich in diese Welt zoomen. Inspiriert von Mechaniken aus dem Gamedesign zeigen Sidescrolls die Energieprojekte von ewz im Detail. 
              </Type>
        </Deutsch>

      </div>


      <img src={GNBimg3}/>

      <div className="comment">
        <English Language={location.search}>
              <Type>
                    Clicking on a project reveals a detail page component - but without breaking the immersion by page refresh.
              </Type>
        </English>


        <Deutsch Language={location.search}>
              <Type>
                    Der Klick auf ein Projekt zeigt seine Details in einer Seitenkomponente - ohne dass die Immersion durch Neuladen der Seite unterbrochen wird.
              </Type>
        </Deutsch>

      </div>


      <img src={GNBimg4}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              Gamification was part of the original concept, with a progress bar and an evolving world tempting users to view the full website. But it got postponed to release 2, also known as the limbo of lost features. The leftovers are these green and yellow buttons.

              </Type>
        </English>

       <Deutsch Language={location.search}>
              <Type>
                    Gamification war Teil des originalen Konzepts, mit einer Progressbar und einer evolvierenden Welt, welche Besucher dazu animieren sollte, die ganze Webseite anzuschauen. Dies wurde auf den Release 2 verschoben, auch bekannt als der Limbo verlorener Features. Was übrig blieb sind grüne und gelbe Buttons.
              </Type>
        </Deutsch>

      </div>


      <img src={GNBimg5}/>


      <Back_To_All_Projects location={location} />


      </Page>
    </Layout>

)
